.contact-card-container {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  height: 100%;
}

.contact-icon-header {
  font-size: 1em;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}

.contact-body,
.contact-footer,
.contact-icon-container {
  text-align: center;
}

.contact-footer {
  color: #11986e;
  font-weight: 600;
}

/*.contact-card-container{*/
/*    transition: all ease-in .4s ;*/
/*}*/

/*.contact-card-container:hover{*/
/*    transform: scale(1.02);*/
/*    box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);*/
/*}*/

.contact-card-icons {
  font-size: 3em !important;
  color: #11986e;
}

@media screen and (max-width: 768px) {
  .contact-card {
    margin-bottom: 1em;
  }
}

/* Contact Form */
.contact-form-header {
  background: #11986e;
}
