/* auth page header */
// colors
$white: #FFFFFF;
$grey: #B3B5B7;
$grey--two: #D8D8D8;
$grey--three: #F8F9F9;
$grey--four: #6E6E6E;
$grey--five: #E1E1E1;
$grey--six: #303030;
$grey--seven: #F1F1F1;
$grey--eight: #E6E6E6;
$grey--nine: #9191A1;
$grey--ten: #F8F8F8;
$grey--eleven: #ECECEC;
$grey--twelve: #AAAAAA;
$grey--thirteen: #929292;
$grey--fourteen: #CBCBCB;
$grey--fifteen: #A8A8A8;
$grey--sixteen: #C6C6C6;
$grey--seventeen: #DDDDDD;
$grey--eighteen: #475993;
$grey--nineteen: #6B6B6B;
$red: #D11402;
$red--two: #D11200;
$orange: #EE3625;
$orange--two: #F73C1B;
$pink: #F93B7C;
$black: #000000;
$black--two: #02011E;
$black--three: #242424;
$black--four: #06142D;
$black--five: #111111;
$black--six: #404740;
$black--seven: #232323;
$black--eight: #404040;
$blue: #4A44F2;
$blue--two: #EEEEFF;
$blue--three: #722ADF;
$brown: #945E38;

// project specific variables
$theme-color--default: #00C569;
$theme-color--addcart: #59cd8b;
$theme-color--heading: $black;
$transition--default: all 0.3s;
$transition--medium: all 0.6s;

// font family
$body-font-family: "Roboto", sans-serif;
$heading-font-family: $body-font-family;

// body text
$body-text-color: #4E4E4E;
$body-font-weight: 400;
$body-line-height: 1.7;
$body-font-size: 14px;

// heading text
$heading-font-weight: 500;

// responsive variables
$extra-large-desktop: "only screen and (min-width: 1500px)";
$large-desktop: "only screen and (min-width: 1200px) and (max-width: 1499px)";
$small-desktop: "only screen and (min-width: 992px) and (max-width: 1199px)";
$extra-large-mobile: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
$extra-extra-small-mobile: "only screen and (max-width: 400px)";

/*=============================================
=            Common            =
=============================================*/

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
}

.border-bottom {
  border-bottom: 1px solid $grey--seven !important;
  &--medium {
    border-bottom: 2px solid $grey--seven !important;
  }
  &--thick {
    border-bottom: 3px solid $grey--seven !important;
  }
}

.body-wrapper {
  &.active-overlay {
    overflow: hidden;
    &:before {
      position: fixed;
      z-index: 999;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      background: transparent;
    }
  }
}

/*=====  End of Common  ======*/
/*=============================================
=            Default            =
=============================================*/

/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;

  color: $body-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: 1;

  margin-top: 0;
  margin-bottom: 0;

  color: $theme-color--heading;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;

  display: inline-block;

  cursor: pointer;
  transition: $transition--default;
  text-decoration: none;

  color: inherit;
  &:focus {
    outline: none;
  }
}
*:focus {
  outline: none;
}
a:focus {
  text-decoration: none;

  color: inherit;
  outline: none;
}
a:hover {
  text-decoration: none;

  color: $theme-color--default;
}

input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;

  list-style: outside none none;
}

select {
  padding-right: 15px;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='black'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 95% 50%;

  -webkit-appearance: none;
  -moz-appearance: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent;
} /* FF 4-18 */
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
} /* FF 19+ */
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
} /* IE 10+ */

/*=====  End of Default  ======*/

/*=============================================
=            Helper            =
=============================================*/

@for $i from 0 through 15 {
  .row-#{1 * $i} {
    margin-right: -1px * $i;
    margin-left: -1px * $i;

    & > [class*="col"] {
      padding-right: 1px * $i;
      padding-left: 1px * $i;
    }
  }
}
@for $i from 16 through 50 {
  .row-#{1 * $i} {
    margin-right: -1px * $i;
    margin-left: -1px * $i;

    // Responsive
    @media #{$large-desktop} {
      margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$small-desktop} {
      margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$extra-large-mobile} {
      margin-right: -15px;
      margin-left: -15px;
    }
    @media #{$large-mobile} {
      margin-right: -15px;
      margin-left: -15px;
    }

    & > [class*="col"] {
      padding-right: 1px * $i;
      padding-left: 1px * $i;

      // Responsive
      @media #{$large-desktop} {
        padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$small-desktop} {
        padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$extra-large-mobile} {
        padding-right: 15px;
        padding-left: 15px;
      }
      @media #{$large-mobile} {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  & > .col,
  & > [class*="col-"] {
    margin: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/*=====  End of Helper  ======*/

/*=============================================
=            Spacing            =
=============================================*/

.space-xy {
  &--10 {
    padding: 10px;
  }
  &--15 {
    padding: 15px;
  }
}

.space-y {
  &--10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &--15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &--60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.space-mt {
  &--10 {
    margin-top: 10px;
  }
  &--15 {
    margin-top: 15px;
  }
  &--20 {
    margin-top: 20px;
  }
  &--30 {
    margin-top: 30px;
  }
  &--40 {
    margin-top: 40px;
  }
  &--70 {
    margin-top: 70px;
  }
}

.space-mb {
  &--5 {
    margin-bottom: 5px;
  }
  &--10 {
    margin-bottom: 10px;
  }
  &--15 {
    margin-bottom: 15px;
  }
  &--20 {
    margin-bottom: 20px;
  }
  &--25 {
    margin-bottom: 25px;
  }
  &--30 {
    margin-bottom: 30px;
  }
  &--40 {
    margin-bottom: 40px;
  }
  &--50 {
    margin-bottom: 50px;
  }
  &--70 {
    margin-bottom: 70px;
  }
  &--80 {
    margin-bottom: 80px;
  }
}

.space-mb-m {
  &--15 {
    margin-bottom: -15px;
  }
  &--20 {
    margin-bottom: -20px;
  }
}

.space-pb {
  &--15 {
    padding-bottom: 15px;
  }
  &--25 {
    padding-bottom: 25px;
  }
  &--30 {
    padding-bottom: 30px;
  }
  &--50 {
    padding-bottom: 50px;
  }
  &--60 {
    padding-bottom: 60px;
  }
  &--90 {
    padding-bottom: 90px;
  }
  &--120 {
    padding-bottom: 120px;
  }
}

.space-pt {
  &--15 {
    padding-top: 15px;
  }
  &--25 {
    padding-top: 25px;
  }
  &--30 {
    padding-top: 30px;
  }
  &--60 {
    padding-top: 60px;
  }
  &--70 {
    padding-top: 70px;
  }
  &--100 {
    padding-top: 100px;
  }
}

/*=====  End of Spacing  ======*/

/*=============================================
=            Footer            =
=============================================*/

.footer-nav-wrapper {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 0 40px;

  border-radius: 60px;
  background-color: $white;
  box-shadow: 0 2px 10px rgba($black, 0.16);
}

.footer-nav-single {
  position: relative;

  display: block;

  width: 85px;
  height: 85px;

  text-align: center;

  border-radius: 50%;

  @media #{$extra-extra-small-mobile} {
    width: 60px;
    height: 60px;
  }

  span {
    font-size: 12px;

    color: $grey--four;
  }
  svg,
  img {
    display: block;

    margin: 0 auto;
    margin-bottom: 5px;
    @media #{$extra-extra-small-mobile} {
      width: 20px;
      height: 20px;
    }
  }

  .menu-wrapper {
    line-height: 1;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: -5px;
    left: -5px;

    visibility: hidden;

    width: calc(100% + 10px);
    height: calc(100% + 10px);

    content: "";

    opacity: 0;
    border-radius: 50%;
    background-color: $white;
  }

  &:hover,
  &.active {
    background-color: $blue;

    span {
      color: $white;
    }
    svg {
      * {
        stroke: $white;
      }
    }

    &:before {
      visibility: visible;

      opacity: 1;
    }
  }
}

/*=====  End of Footer ======*/
/*=============================================
=            Header            =
=============================================*/
header {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;

  background-color: $white;
  .header-search {
    max-width: 230px;
    form {
      position: relative;
      input {
        font-size: 12px;

        width: 100%;
        padding: 7px 30px 7px 40px;

        color: #666666;
        border: none;
        border-radius: 25px;
        background-color: #EFEFEF;
      }
      svg,
      img {
        position: absolute;
        top: 50%;
        left: 15px;

        transform: translateY(-50%);
      }
    }
  }

  .header-menu-trigger {
    padding: 0;

    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
}

/* offcanvas menu */

.profile-card {
  &__image {
    img {
      border-radius: 50%;
    }
  }
  &__content {
    p {
      font-weight: 500;

      color: $black--four;
      span {
        font-size: 12px;
        font-weight: 400;

        display: block;
      }
    }
  }
}

.offcanvas-menu {
  position: fixed;
  z-index: 9999;
  top: 70px;
  right: 0;

  visibility: hidden;

  width: 280px;
  max-width: 100%;
  height: 100vh;
  margin-left: auto;
  padding: 40px;

  transition: $transition--medium;
  transform: translateX(100%);

  background-color: #F1F1F1;
  background-image: linear-gradient(to bottom, #F1F1F1 0%, #F2F8FF 100%);
  box-shadow: -5px 0 15px rgba($black, 0.16);

  &.active {
    visibility: visible;

    transform: translateX(0);
  }
}

.offcanvas-navigation {
  overflow-y: scroll;

  height: 400px;
  margin: 0 -40px;
  padding: 0 40px;

  li {
    display: flex;
    align-items: center;

    padding: 15px 10px 15px 30px;

    border-bottom: 2px solid $white;

    &:nth-child(1) {
      border-top: 2px solid $white;
    }

    span {
      display: block;

      margin-right: 15px;

      svg,
      img {
        width: 14px;
        height: 14px;
      }
    }

    a {
      display: block;

      width: 100%;

      color: $black--four;
    }
  }
}

/* search keyword */

.search-keyword-area {
  display: none;
  &.active {
    display: block;
  }
}

.search-keyword-header {
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    font-size: 14px;

    flex-basis: 80%;
  }

  &__gl-switcher {
    flex-basis: 20%;
    button {
      padding: 0;

      border: none;
      background: none;
    }
  }
}

.search-keywords {
  margin-bottom: -10px;
  li {
    display: inline-block;

    margin-right: 10px;
    margin-bottom: 10px;
    a {
      font-size: 13px;
      line-height: 1.3;

      display: block;

      padding: 5px 15px;

      color: $black--five;
      border: 1px solid $grey--five;
      background-color: $white;
    }
  }
}

/*=====  End of Header  ======*/
.bg-color--grey {
  background-color: rgba($grey, 0.10);
}
.bg-color--grey2 {
  background-color: #F8F8F8;
}

.bg-color--gradient {
  background-image: linear-gradient(to bottom,  #FFFFFF 0%,#F1F1F1 100%);
}
/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area {
  .page-title {
    font-size: 14px;
  }
  .back-link {
    font-weight: 500;

    color: $black;
    i {
      margin-right: 3px;
    }
  }
}


/*=====  End of breadcrumb  ======*/

/*=============================================
=            No items found            =
=============================================*/

.no-items-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin-top: 200px;

  text-align: center;
  &__image {
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    p {
      a {
        font-weight: 500;

        display: block;

        text-decoration: underline;
      }
    }
  }
}

/*=====  End of No items found  ======*/
/*=============================================
=            preloader            =
=============================================*/

.preloader-active {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100vw;
  height: 100vh;
  .preloader-area-wrap {
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    background-color: $white;

    .img-loader {
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      left: calc(50% - 15px);

      display: block;

      width: 30px;
      height: 30px;

      transition: $transition--default;
      animation: rotate-center 0.6s linear infinite both;

      border-width: 3px;
      border-style: solid;
      border-color: $theme-color--default $theme-color--default
      $theme-color--default transparent;
      border-radius: 50%;
    }
  }
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*=====  End of preloader  ======*/
/*=============================================
=            Section Title            =
=============================================*/
.section-title {
  font-size: 18px;

  position: relative;
  a {
    font-size: 10px;
    font-weight: 400;

    position: absolute;
    top: 50%;
    right: 0;

    transform: translateY(-50%);
    span {
      div {
        display: inline-block;
      }
    }
  }
}

/*=====  End of Section Title  ======*/


.auth-page-header {
  &__title {
    font-size: 30px;
    font-weight: 700;

    margin-bottom: 5px;

    color: $black--two;
  }

  &__text {
    color: $grey--four;
  }
}

/* auth page body */

.auth-page-body {
  .auth-form {
    padding: 40px 20px;

    background-color: $white;
    &__single-field {
      label {
        font-size: 14px;

        margin-bottom: 20px;

        color: $black--two;
      }
      input,
      textarea {
        font-size: 18px;

        display: block;

        width: 100%;
        padding-bottom: 10px;

        color: $black--two;
        border: none;
        border-bottom: 2px solid $grey--eight;

        &:focus {
          border-bottom: 2px solid $theme-color--default;
        }
      }

      ::placeholder {
        opacity: 1; /* Firefox */

        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey--four;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey--four;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $grey--four;
      }
    }

    &__button {
      font-size: 16px;
      line-height: 1;

      width: 100%;
      padding: 15px 0;

      color: $white;
      border: none;
      background: none;
      background-color: $theme-color--default;
    }

    &__info-text {
      color: $grey--four;
      a {
        color: $theme-color--default;
      }
    }
  }
}

/* auth page footer */

.auth-page-separator {
  font-size: 18px;
  line-height: 1;

  display: block;

  color: $black--two;
}

.auth-page-social-login {
  button {
    line-height: 1;

    position: relative;

    display: block;

    width: 100%;
    margin-bottom: 20px;
    padding: 15px 0;

    color: $black;
    border: 1px solid $grey--seventeen;
    border-radius: 5px;
    background-color: transparent;
    &:last-child {
      margin-bottom: 0;
    }

    svg,
    img {
      position: absolute;
      top: 50%;
      left: 30px;

      transform: translateY(-50%);
    }
  }
}
/*=============================================
=            cart            =
=============================================*/

.cart-plus-minus {
  position: relative;

  display: inline-block;

  width: 90px;
  height: 30px;
  padding: 0;
  .qtybutton {
    font-size: 16px;
    line-height: 30px;

    position: absolute;

    float: inherit;

    width: 20px;
    margin: 0;

    cursor: pointer;
    transition: all 0.3s ease 0s;
    text-align: center;

    color: $black--five;
    border: none;
    background: none;
  }
  .dec.qtybutton {
    top: 0;
    left: 0;

    height: 30px;

    border-right: 1px solid $grey--eight;
  }
  .inc.qtybutton {
    top: 0;
    right: 0;

    height: 30px;

    border-left: 1px solid $grey--eight;
  }
  input.cart-plus-minus-box {
    font-size: 14px;

    float: left;

    width: 90px;
    height: 30px;
    margin: 0;
    padding: 0;

    text-align: center;

    color: $black--five;
    border: 1px solid $grey--eight;
    background: transparent none repeat scroll 0 0;
  }
}

.discount-code-wrapper {
  padding: 30px 0;

  border-top: 1px solid $grey--seven;
  border-bottom: 1px solid $grey--seven;
  border-radius: 5px;

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;
    }
    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
  }

  .discount-code {
    margin: 21px 0 0;
    p {
      margin: 0 0 15px;
    }
    form {
      input {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        padding-left: 10px;

        border: 1px solid $grey--seven;
      }
      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;

        padding: 13px 30px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: $white;
        border: medium none;
        background-color: $theme-color--default;
      }
    }
  }
}

.grand-total {
  padding: 30px 0;

  border-top: 1px solid $grey--seven;
  border-bottom: 1px solid $grey--seven;

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;
    }
    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
    .section-bg-gary-cart {
      position: relative;
      z-index: 9;
    }
  }
  h5 {
    font-size: 14px;

    margin: 36px 0 27px;
    span {
      font-size: 18px;
      font-weight: 500;

      float: right;
    }
  }
  .total-shipping {
    margin: 0 0 27px;
    padding: 28px 0;

    border-top: 1px solid $grey--seven;
    border-bottom: 1px solid $grey--seven;
    h5 {
      font-size: 14px;

      margin: 0;
    }
    ul {
      padding: 19px 0 0 0;
      li {
        margin: 0 0 6px;

        list-style: outside none none;

        &:last-child {
          margin: 0 0 0;
        }

        span {
          float: right;
        }
      }
    }
  }
  h4.grand-total-title {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 25px;

    span {
      float: right;
    }
  }
  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: block;

    padding: 18px 10px 17px;

    text-align: center;
    text-transform: uppercase;

    color: $white;
    background-color: $theme-color--default;
  }
}
/*=====  End of cart  ======*/

/*=============================================
=            category slider            =
=============================================*/

.category-item {
  text-align: center;
  width: 60px;
  &__image {
    position: relative;

    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 15px;

    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 5px 20px rgba($black--three, 0.05);

    a {
      position: absolute;
      top: 50%;
      left: 50%;

      display: inline-block;

      transform: translate(-50%, -50%);
    }
  }
  &__title {
    a {
      line-height: 1;

      display: block;

      color: $black;
    }
  }
}

/*=====  End of category slider  ======*/

/*=============================================
=            chat            =
=============================================*/

.chat-wrap {
  padding: 15px 15px 40px 15px;
}

/*Chat List*/
.chat-list {
  margin: 0;
  padding: 0;

  list-style: none;
  & li {
    & .chat {
      margin-bottom: 25px;
    }
    &:nth-child(2n) {
      & .chat {
        & .head {
          flex-direction: row-reverse;
          & h5 {
            text-align: right;
          }
          & span {
            margin-right: 0;
            margin-left: 15px;
          }
        }
        & .body {
          flex-direction: row-reverse;
          .image {
            margin-right: 0;
            margin-left: 15px;
          }
          & .content {
            margin-right: 15px;
            margin-left: 0;
            &::before {
              right: -10px;
              left: auto;

              border-width: 6px 0 6px 10px;
              border-color: transparent transparent transparent $white;
            }
          }
        }
      }
    }
  }
}

/*Chat*/
.chat {
  // Head
  & .head {
    position: relative;

    margin-bottom: 5px;
    & h5 {
      font-size: 14px;
      line-height: 18px;

      flex-grow: 1;

      margin: 0;

      text-align: left !important;
    }
    & span {
      font-size: 12px;
      line-height: 18px;

      margin-right: 20px;
      margin-bottom: 3px;
      margin-left: 0 !important;
    }
    & a {
      position: absolute;
      right: 0;
      bottom: 0;

      margin-bottom: 3px;

      color: $theme-color--default;
      & i {
        font-size: 18px;
        line-height: 18px;

        display: block;
      }
    }
  }
  // Body
  & .body {
    display: flex;
    align-items: center;
    & .image {
      flex: 1 0 40px;
      flex-direction: column;

      max-width: 40px;
      margin-right: 15px;
      & img {
        overflow: hidden;

        width: 100%;
        height: auto;

        border-radius: 50%;
      }
    }

    & .content {
      position: relative;

      margin: 15px 0 0 !important;
      padding: 15px;

      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 0 5px rgba(24, 24, 24, 0.05);
      &::before {
        position: absolute;
        top: 28px;
        left: -10px;

        display: none;

        content: "";

        border-width: 6px 10px 6px 0;
        border-style: solid;
        border-color: transparent $white transparent transparent;
      }
      & p {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}

/*Chat Submission*/
.chat-submission {
  position: fixed;
  bottom: 85px;

  width: 100%;

  border-top: 1px solid $grey--five;
  background-color: $white;

  @media #{$extra-extra-small-mobile} {
    bottom: 60px;
  }
  & form {
    position: relative;

    display: flex;
    flex: 1 0 100%;

    padding: 10px 120px 10px 15px;
    & input {
      line-height: 20px;

      width: 100%;
      padding: 15px 15px;

      border: none;
      background-color: transparent;
    }
    & .buttons {
      position: absolute;
      top: 50%;
      right: 30px;

      transform: translateY(-50%);
      .submit,
      label {
        width: 40px;
        height: 40px;
        padding: 0;

        text-align: center;

        color: $white;
        border: none;
        border-radius: 50%;
        background: none;
        background-color: $theme-color--default;
        i,
        svg {
          height: 40px;
          margin-right: 3px;
        }
      }
      .file-upload {
        line-height: 1;

        margin: 0;
        margin-right: 5px;

        cursor: pointer;
        & input {
          display: none;
        }
        & i,
        svg {
          font-size: 15px;

          height: 36px;

          transform: rotate(90deg);
        }
      }
    }
  }
}

/*=====  End of chat  ======*/

/*=============================================
=            checkout            =
=============================================*/


.checkout-form {
  padding: 40px 20px;

  background-color: $white;
  &__single-field {
    label {
      font-size: 14px;

      margin-bottom: 20px;

      color: $black--two;
    }
    input,
    textarea {
      font-size: 18px;

      display: block;

      width: 100%;
      padding-bottom: 10px;

      color: $black--two;
      border: none;
      border-bottom: 2px solid $grey--eight;

      &:focus {
        border-bottom: 2px solid $theme-color--default;
      }
    }

    ::placeholder {
      /* Firefox */
      opacity: 1;

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--four;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey--four;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey--four;
    }
  }

  &__button {
    font-size: 16px;
    line-height: 1;

    width: 100%;
    padding: 15px 0;

    color: $white;
    border: none;
    background: none;
    background-color: $theme-color--default;
  }
}


.your-order-area {
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: $black;
  }
  .your-order-wrap {
    padding: 30px 15px;

    background: $grey--seven;

    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $black;
          }
        }
      }
      .your-order-middle {
        margin: 29px 0;
        padding: 19px 0 18px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 10px;
          }
        }
      }
      .your-order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: $black;
          }
        }
      }
      .your-order-total {
        margin: 18px 0 33px;
        padding: 17px 0 19px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: $black;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color--default;
          }
        }
      }
    }
  }

  .place-order > a {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $white;
    border-radius: 50px;
    background-color: $theme-color--default;
    &:hover {
      background-color: #333;
    }
  }
}


/*=====  End of checkout  ======*/

/* contact content */

.contact-page-content {
  &__image {
    height: 200px;
  }
  &__text {
    h3 {
      font-size: 18px;

      margin-bottom: 10px;
    }
  }

  &__text-block {
    h4 {
      font-size: 14px;

      margin-bottom: 5px;
    }
  }
}

.map-wrapper {
  iframe {
    width: 100%;
    height: 300px;

    border: none;
  }
}/*=============================================
=            hero slider            =
=============================================*/

.hero-slider-wrapper {
  .swiper-slide-active {
    .hero-slider-content {
      > * {
        &:nth-child(1) {
          animation-name: fadeInDown;
          animation-duration: 1s;
          animation-delay: 0.5s;
        }
        &:nth-child(2) {
          animation-name: fadeInDown;
          animation-duration: 1s;
          animation-delay: 1.5s;
        }
      }
    }
  }

  .swiper-pagination {
    line-height: 1;

    position: absolute;
    bottom: 20px;
    left: 20px;

    display: inline-block;

    text-align: left;
    &-bullet {
      font-size: 0;

      display: inline-block;

      width: 15px;
      height: 15px;
      margin: 0 !important;
      margin-right: 5px !important;
      padding: 0;

      opacity: 1;
      border: 3px solid $grey--two;
      border-radius: 50%;
      background: none;
      background-color: $white;

      &-active {
        background-color: $orange;
      }

      &:hover,
      &:focus {
        button {
          background-color: $orange;
        }
      }
    }
  }
}

.hero-slider-item {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: auto !important;
  height: 220px;
  margin: 5px;

  border-radius: 8px;
  box-shadow: 0 4px 6px rgba($black, 0.1);
}

.hero-slider-content {
  width: 300px;
  max-width: 100%;
  & > * {
    animation-name: fadeOutUp;
    animation-duration: 1s;

    animation-fill-mode: both;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;

    color: $black--two;
  }

  &__text {
    font-weight: 500;

    color: $black--two;
  }
}

/*=====  End of hero slider  ======*/

/*=============================================
=            notification            =
=============================================*/

.notification-item {
  padding: 20px 15px;

  border-bottom: 1px solid $grey--two;
  &__content {
    margin-bottom: 10px;
    .highlight {
      font-weight: 700;

      color: $black;
    }
  }
  &__time {
    font-size: 12px;
    line-height: 1;

    position: relative;

    padding-left: 15px;

    vertical-align: middle;
    span {
      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%);
    }
  }

  &--unread {
    background-color: $blue--two;
  }
}

/*=====  End of notification  ======*/

/*=============================================
=            product             =
=============================================*/

/* featured product */
.featured-product {
  padding: 10px;

  border-radius: 10px;
  background-color: $grey--three;
  &__image {
    margin-bottom: 5px;

    text-align: center;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      font-size: 11px;
      line-height: 1;

      padding: 5px;

      color: $white;
      border-radius: 10px;
      background-color: $orange;
      .main-price {
        text-decoration: line-through;
      }
    }
    .icon {
      line-height: 1;
      button {
        padding: 0;

        border: none;
        background: none;

        svg {
          * {
            fill: transparent;
            stroke: $black--two;
          }
        }

        &:hover,
        &.active {
          svg {
            * {
              fill: $black--two;
            }
          }
        }
      }
    }
  }
}

/* grid product */

.grid-product {
  padding: 15px;

  background-color: $grey--three;
  box-shadow: 0 4px 6px rgba($black, 0.1);
  &__image {
    position: relative;

    margin-bottom: 20px;

    text-align: center;
    .icon {
      position: absolute;
      top: 0;
      right: 0;

      width: 30px;
      height: 30px;
      padding: 0;

      border: none;
      border-radius: 50%;
      background: none;
      box-shadow: 0 4px 6px rgba($black, 0.1);
      &:hover,
      &.active {
        background-color: $blue;
        svg {
          * {
            fill: $white;
          }
        }
      }
    }
  }
  &__content {
    .title {
      font-size: 16px;
      line-height: 1.3;
    }
    .category {
      font-size: 12px;
      line-height: 1;

      display: inline-block;

      margin-bottom: 7px;

      color: $grey--four;
    }

    .price {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;

      color: $theme-color--default;

      .main-price {
        text-decoration: line-through;
      }
    }
  }
}

/* list product */

.list-product {
  position: relative;

  display: flex;

  padding: 30px 15px;
  &__image {
    flex-basis: 145px;

    margin-right: 20px;

    background-color: $grey--three;
  }

  &__content {
    flex-basis: calc(100% - 165px);
    .title {
      font-size: 16px;
      line-height: 1.3;

      margin-bottom: 5px;
    }
    .category {
      font-size: 12px;
      line-height: 1;

      display: inline-block;

      margin-bottom: 7px;

      color: $grey--four;
    }

    .price {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 10px;

      color: $theme-color--default;

      .main-price {
        text-decoration: line-through;
      }
    }
  }

  .icon {
    position: absolute;
    top: 30px;
    right: 15px;

    width: 30px;
    height: 30px;
    padding: 0;

    border: none;
    border-radius: 50%;
    background: none;
    box-shadow: 0 4px 6px rgba($black, 0.1);
    &:hover,
    &.active {
      background-color: $blue;
      svg {
        * {
          fill: $white;
        }
      }
    }
  }
}

/* cart product */

.cart-product {
  display: flex;
  align-items: center;

  padding: 15px;
  &__image {
    flex-basis: 90px;

    margin-right: 10px;

    background-color: $grey--three;
  }

  &__content {
    flex-basis: calc(100% - 190px);
    .title {
      font-size: 16px;
      line-height: 1.3;
    }
    .category {
      font-size: 12px;
      line-height: 1;

      display: inline-block;

      margin-bottom: 7px;

      color: $grey--four;
    }

    .price {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 10px;

      color: $theme-color--default;

      .main-price {
        text-decoration: line-through;
      }
    }
  }
  &__status {
    flex-basis: 90px;

    text-align: right;
    p {
      span {
        color: $theme-color--default;
      }
    }

    a,
    button {
      font-size: 20px;

      color: $theme-color--default;
    }

    button {
      margin-left: 15px;
      padding: 0;

      border: none;
      background: none;

      &.danger {
        color: $red--two;
      }
    }
  }
}

/* shop product image slider */

.product-image-slider-wrapper {
  position: relative;

  overflow: hidden;
  .product-image-single {
    text-align: center;
  }

  .swiper-container {
    overflow: visible;

    padding-top: 60px;
    padding-bottom: 60px;

    border-bottom-right-radius: 100px;
    background-color: $grey--three;
    box-shadow: 0 1px 2px rgba($black, 0.16);
  }

  .swiper-pagination {
    line-height: 1;

    position: absolute;
    bottom: -30px;
    left: 50%;

    transform: translateX(-50%);
    .swiper-pagination-bullet {
      font-size: 0;

      display: inline-block;

      width: 15px;
      height: 15px;
      margin-right: 15px;
      padding: 0;

      opacity: 1;
      border: 1px solid rgba($grey--six, 0.3);
      border-radius: 50%;
      background: none;
      &:last-child {
        margin-right: 0;
      }
      &-active {
        border-color: $theme-color--default;
        background-color: $theme-color--default;
      }
    }
  }
}

/* shop product header */

.product-content-header {
  display: flex;
  justify-content: space-between;
  &__main-info {
    .title {
      font-size: 18px;

      margin-bottom: 5px;
    }

    .price {
      font-size: 22px;
      font-weight: 500;

      color: $theme-color--default;

      .main-price {
        text-decoration: line-through;
      }
    }
  }

  .rating {
    display: flex;
    align-items: center;

    &__stars {
      margin-right: 10px;
      li {
        display: inline-block;

        margin-right: 3px;
      }
    }

    &__text {
      padding: 0 12px;

      color: $white;
      border-radius: 20px;
      background-color: $theme-color--default;
    }
  }

  &__wishlist-info {
    svg {
      width: 24px;
      height: 21px;
      margin-bottom: 5px;
    }
    .count {
      display: block;
    }
  }
}

/* shop product description */

.product-content-description,
.product-content-safety {
  h4 {
    font-size: 14px;
  }
}

/* shop product safety */

.product-content-safety {
  div {
    display: inline-block;
    svg {
      margin-right: 5px;
    }
  }
}

/* product-color-picker */

.product-color-picker {
  h3 {
    font-size: 16px;
  }

  form {
    .color-picker {
      line-height: 1;
      li {
        display: inline-block;

        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
        input[type="radio"] {
          width: 0;

          opacity: 0;
          &:checked {
            & + label {
              border: 4px solid $theme-color--default;
            }
          }
        }
        label {
          line-height: 1;

          width: 40px;
          height: 40px;
          margin-bottom: 0;

          border: 4px solid $grey--eight;
          border-radius: 50%;

          &.black {
            background-color: $black--six;
          }
          &.violet {
            background-color: $blue--three;
          }
          &.pink {
            background-color: $pink;
          }
          &.brown {
            background-color: $brown;
          }
        }
      }
    }
  }
}

/* shop product button */

.shop-product-button {
  display: flex;
  button {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    display: block;
    flex-basis: 50%;

    padding: 15px 0;

    border: none;
    background: none;

    &.wishlist {
      color: $black;
      /*
      background-color: $blue--two;
      */
    }

    &.back {
      color: $white;
      background-color: $red;
    }
    &.successcodenra {
      color: $white;
      background-color: $theme-color--addcart;
    }
  }
}

/* shop header */

.shop-header {
  .category-title {
    font-size: 14px;

    text-transform: capitalize;
  }

  .filter-trigger {
    font-weight: 500;

    padding: 0;

    color: $black;
    border: none;
    background: none;
  }
  .back-link {
    font-weight: 500;

    color: $black;
    i {
      margin-right: 3px;
    }
  }
}

/* shop filter */

.shop-filter {
  overflow: hidden;

  height: 0;

  transition: height 0.4s ease-in-out;

  background: $grey--ten;
  box-shadow: 0 6px 6px rgba($black, 0.16);
  &-block {
    &__title {
      font-size: 14px;
    }
    &__content {
      ul {
        display: flex;
        li {
          button {
            padding: 0;
          }
        }
      }

      .widget-price-range {
        .irs--round {
          line-height: 1;
          .irs {
            .irs-line {
              height: 4px;

              border-radius: 0;
              background-color: $grey--fourteen;
            }
            .irs-from,
            .irs-to {
              font-size: 12px;

              top: 5px;

              padding: 5px;

              color: $grey--thirteen;
              background-color: transparent;
              &:before {
                display: none;
              }
            }
          }

          .irs-bar {
            background: $black;
          }

          .irs-handle {
            top: 30px;

            width: 15px;
            height: 15px;

            border: 4px solid $black;
          }
        }
      }
    }

    &__color {
      li {
        margin-right: 20px;
        button {
          line-height: 1;

          width: 40px;
          height: 40px;
          margin-bottom: 0;

          border: 4px solid $grey--eight;
          border-radius: 50%;

          &.black {
            background-color: $black--six;
          }
          &.violet {
            background-color: $blue--three;
          }
          &.pink {
            background-color: $pink;
          }
          &.brown {
            background-color: $brown;
          }

          &:hover,
          &.active {
            border: 4px solid $theme-color--default;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__category {
      li {
        margin-right: 20px;
        button {
          font-size: 12px;

          position: relative;

          text-transform: capitalize;

          color: $grey--thirteen;
          border: none;
          background: none;
          &.active {
            font-weight: 500;

            color: $black--three;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .shop-filter-btn {
      font-size: 16px;
      font-weight: 500;

      width: 135px;
      padding: 5px 0;

      border: 1px solid $grey--fifteen;
      border-radius: 20px;

      &.apply-btn {
        margin-right: 30px;

        color: $white;
        border-color: $theme-color--default;
        background-color: $theme-color--default;
      }

      &.cancel-btn {
        color: $grey--sixteen;
        background-color: transparent;
      }
    }
  }
}

/* shop layout switcher */
.shop-layout-switcher {
  button {
    line-height: 1;

    margin-right: 15px;
    padding: 0;

    border: none;
    background: none;
    &:last-child {
      margin-right: 0;
    }

    &.active {
      svg {
        fill: $theme-color--default;
      }
    }
  }
}

/*=====  End of product   ======*/
/*=============================================
=            profile            =
=============================================*/

/* profile header */

.profile-header {
  /*display: flex;*/

  &__image {
    flex-basis: 120px;

    margin-right: 15px;
    img {
      border-radius: 15px;
    }
  }
  &__content {
    flex-basis: calc(100% - 135px);
    .name {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .profile-info {
    display: flex;

    /*width: 200px;*/
    max-width: 100%;
    &-block {
      flex-basis: 50%;
      &__value {
        font-size: 12px;
        font-weight: 700;

        color: $black--four;
      }
      &__title {
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  .profile-level {
    &__title {
      font-size: 12px;
      font-weight: 500;

      color: $black--four;
    }

    &__progress {
      height: 4px;

      background-color: rgba($grey--nine, 0.10);
      .progress-bar {
        background-color: $orange--two;
      }
    }
  }
}


/* profile body */

.profile-info-table {
  border-radius: 10px;
  background-color: $white;
  .profile-info-block {
    display: flex;
    align-items: center;

    padding: 15px 30px;

    color: $black--four;
    border-bottom: 2px solid $grey--seven;

    &:last-child {
      border-bottom: none;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;

      flex-basis: 50%;
    }
    &__value {
      font-size: 12px;
      font-weight: 300;

      flex-basis: 50%;

      text-align: right;
      a {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.edit-profile-form {
  padding: 40px 20px;

  background-color: $white;
  &__single-field {
    label {
      font-size: 14px;

      margin-bottom: 20px;

      color: $black--two;
    }
    input,
    textarea {
      font-size: 18px;

      display: block;

      width: 100%;
      padding-bottom: 10px;

      color: $black--two;
      border: none;
      border-bottom: 2px solid $grey--eight;

      &:focus {
        border-bottom: 2px solid $theme-color--default;
      }
    }

    ::placeholder {
      opacity: 1; /* Firefox */

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--four;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey--four;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey--four;
    }
  }

  &__button {
    font-size: 16px;
    line-height: 1;

    width: 100%;
    padding: 15px 0;

    color: $white;
    border: none;
    background: none;
    background-color: $theme-color--default;
  }
}


/*=====  End of profile  ======*/
/*=============================================
=            welcome area            =
=============================================*/

.welcome-slider-wrapper {
  position: relative;
  .logo {
    img {
      margin: 0 auto;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 500;
  }
  .text {
    font-size: 16px;

    color: $black--eight;
  }

  .welcome-btn {
    font-size: 16px;
    line-height: 1;

    display: block;

    width: 140px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px 0;

    color: $white;
    border-radius: 20px;
    background-color: $red--two;
  }

  .skip-btn {
    color: $grey--nineteen;
    border: none;
    background: none;
  }

  .slick-dots {
    line-height: 1;

    position: absolute;
    bottom: 20px;
    left: 50%;

    transform: translateX(-50%);
    li {
      display: inline-block;

      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.slick-active {
        button {
          background-color: $red--two;
        }
      }
      button {
        font-size: 0;

        width: 8px;
        height: 8px;
        padding: 0;

        border: none;
        border-radius: 50%;
        background: none;
        background-color: $grey--four;
      }
    }
  }
}

.single-welcome-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  height: 100vh;
  padding: 30px 0;
}

/*=====  End of welcome area  ======*/


.rounded-xl-r {
  border-bottom-left-radius: 0.90rem;
  border-bottom-right-radius: 0.90rem;
}

.last-session-item {
  border: 1px #bfbfbf dotted;
background-color: #ffebd9;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
}
