.label-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .admin-user-label {
    font-size: 14px;
  }
}
